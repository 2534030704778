@import "main.css";
.instagram-media-rendered {
  background: white;
  min-height: 62vh;
  max-width: 40vw;
  width: calc(100% - 2px);
  border-radius: 10px;
  display: block;
}


// Extra small devices (portrait phones, between 320px and 575px)
@media (min-width: 320px) and (max-width: 575px) {
  .instagram-media-rendered {
    max-width: 100%;
    min-height: 45vh !important;
    margin-top: 20px;
  }
}

// Small devices (landscape phones, between 576px and 767px)
@media (min-width: 576px) and (max-width: 767px) {
  .instagram-media-rendered {
    max-width: 100%;
    min-height: 45vh !important;
    margin-top: 20px;

  }
}

// Medium devices (tablets, between 768px and 991px)
@media (min-width: 768px) and (max-width: 991px) {
  .instagram-media-rendered {
    max-width: 100%;
    min-height: 45vh !important;
    margin-top: 20px;

  }
}

// Large devices (desktops, between 992px and 1199px)
@media (min-width: 992px) and (max-width: 1199px) {
  .instagram-media-rendered {
    max-width: 100%;
  }
}

// X-Large devices (large desktops, between 1200px and 1399px)
@media (min-width: 1200px) and (max-width: 1399px) {
  .instagram-media-rendered {
    max-width: 100%;

  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1700px) {
  .instagram-media-rendered {
    min-height: 52vh;
    max-width: 30vw;
  }
}
